<script setup>
// eslint-disable-next-line no-unused-vars
import {Spotlight} from 'spotlight.js';
import SectionTitle from "@/components/SectionTitle.vue";
import PhotoAlbum from "@/components/PhotoAlbum.vue";
import albums from '@/assets/json/albums.json'

console.log(Spotlight);
</script>

<template>
  <section>
    <div id="container">
      <SectionTitle color="dark" title="Fotos & Videos:"/>
      <div class="album-container">

        <PhotoAlbum v-for="(album) in albums.albums"
                    :key="album.name"
                    :name="album['name']"
                    :date="album['date']"
                    :thumbnail="album['thumbnail_url']"
                    :content_urls="album['content_urls']"
        />

      </div>
    </div>
    <iframe src="https://www.youtube-nocookie.com/embed/ntEtwZ_Mqps?si=jXWVmz7taKMhmh4h"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  </section>
</template>

<style scoped>

section {
  width: 100%;
  background: var(--bright);
}

#container {
  max-width: 1000px; /* Limiting the width for extra large screens */
  padding: 50px 40px;
  background: var(--bright);
  /* To center the text horizontally */
  margin: auto auto 5rem;
}

.album-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5rem;
  justify-items: center;
}

/* For bigger screens*/
@media (min-width: 768px) {
  .album-container {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    row-gap: 5rem;
    justify-items: center;
  }
}

iframe {
  display: block;
  margin: 0 auto;
  width: 280px;
  height: 160px;
}

/* For bigger screens*/
@media (min-width: 768px) {
  iframe {
    display: block;
    margin: 0 auto;
    width: 560px;
    height: 315px;
  }
}
</style>