<template>
  <SocialBar/>
  <!--<AnnouncementBar/>-->
  <div id="main">
    <HeroSection/>
    <SiteContent/>
  </div>


</template>

<script>
import HeroSection from "@/components/SectionHero.vue";
import SiteContent from "@/components/SiteContent";
import SocialBar from "@/components/SocialBar.vue";
//import AnnouncementBar from "@/components/AnnouncementBar.vue";

export default {
  name: 'App',
  components: {
    //AnnouncementBar,
    SocialBar,
    SiteContent,
    HeroSection,
  },
  created() {
    document.title = "TGV - Tin God's Village"
  }
}
</script>

<style>

:root {
  --bright: #e9e9ea;
  --dark: #011515;
}

* {
  scroll-behavior: smooth;

  /* GLOBAL RESET */
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 10px;
}

body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: sans-serif;
  line-height: 1.5;
  font-size: 18px;
}

#main {
  width: 100%;
  height: 100%;
  perspective: 4px; /* The depth */
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#main::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "RockIt";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("RockIt"), url("@/assets/fonts/RockIt-yjYm.ttf") format("truetype");
}
</style>
